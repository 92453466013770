import { config } from './environment';

const log = Log.getLogger('log:http');

export function createURL(url: string, params?: { [key: string]: string }) {
    const endpoint = new URL(url, config.baseUrl);

    if (!!params) {
        Object.entries(params).forEach((entry) => endpoint.searchParams.append(entry[0], entry[1]));
    }

    return endpoint;
}

async function getRequestHeaderAsync(): Promise<Headers> {
    return Promise.resolve()
        .then(() => {
            const headers: Headers = new Headers();
            headers.append("Content-Type", "application/json");

            return headers;
        });
}

function handleJSON(res: Response) {
    if (!res.ok) {
        throw new Error(`${res.url}: CODE ${res.status}`);
    }

    return res.json();
}

function handleVoid(res: Response) {
    if (!res.ok) {
        throw new Error(`${res.url}: CODE ${res.status}`);
    }

    return undefined;
}


function throwNetworkError(e: any) {
    if (e.name !== 'AbortError') {
        log.error(e);
    }

    throw e;
}

class HttpClient {
    constructor() {
        log.info('Init HTTP Client with base address', config.baseUrl);
    }

    /**
     * Effettua una chiamata di tipo GET
     * @param url url da chiamare, se relativa, viene usata la chiave config.baseUrl come base del path
     * @param params parametri opzionali da aggiungere alla url come query parameters
     */
    public async get<Response>(
        url: string,
        params?: { [key: string]: string },
        abortController?: AbortController,
    ): Promise<Response> {
        log.info('GET', url, params || '');
        const endpoint = createURL(url, params);

        return getRequestHeaderAsync()
            .then(headers => fetch(
                new Request(endpoint.toString(), {
                    method: "GET",
                    headers,
                    signal: abortController?.signal
                })
            ))
            .then(handleJSON)
            .catch(throwNetworkError);
    }

    /**
     * Effettua una chiamata di tipo POST
     * @param url url da chiamare, se relativa, viene usata la chiave config.baseUrl come base del path
     * @param body oggetto da inviare
     * @param params parametri opzionali da aggiungere alla url come query parameters
     */
    public async post<Request, Response>(
        url: string,
        body: Request,
        params?: { [key: string]: string },
        abortController?: AbortController,
    ): Promise<Response> {
        log.info('POST', url, params || '');

        const endpoint = createURL(url, params);

        return getRequestHeaderAsync()
            .then(headers => fetch(
                new Request(endpoint.toString(), {
                    method: "POST",
                    headers,
                    body: JSON.stringify(body),
                    signal: abortController?.signal
                })
            ))
            .then(handleJSON)
            .catch(throwNetworkError);

    }

    /**
     * Effettua una chiamata di tipo PUT
     * @param url url da chiamare, se relativa, viene usata la chiave config.baseUrl come base del path
     * @param body oggetto da inviare
     * @param params parametri opzionali da aggiungere alla url come query parameters
     */
    public async put<Request, Response>(
        url: string,
        body: Request,
        params?: { [key: string]: string },
        responseType?: 'JSON' | 'VOID'
    ): Promise<Response> {
        log.info('PUT', url, params || '');

        const endpoint = createURL(url, params);

        return getRequestHeaderAsync()
            .then(headers => fetch(
                new Request(endpoint.toString(), {
                    method: "PUT",
                    headers,
                    body: JSON.stringify(body),
                })
            ))
            .then(responseType === 'VOID' ? handleVoid : handleJSON)
            .catch(throwNetworkError);
    }
}

/**
 * Singleton del client http che si occupa di aggiungere i corretti
 * header e base-path alle chiamate http
 */
export const http = new HttpClient();
