import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { NotFound } from './NotFound';
import { Certificate } from './Certificate';
import { Home } from './Home';
import './App.css';

const log = Log.getLogger('App');
log.debug('import');

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/:guid" exact >
          {/* <h2>San Marino Digital Covid Certificate - Universal QR code Verifier</h2> */}
            <Certificate />
          </Route>
          <Route path="/" exact >
            <Home />
          </Route>
          <Route path="*">
          {/* <h2>San Marino Digital Covid Certificate - Universal QR code Verifier</h2> */}
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
