export const EMPTY_PERSON: CertificateModelPerson = {
    fn: '',
    fnt: '',
    gn: '',
    gnt: ''
}

export const EMPTY_CERTIFICATE: CertificateModel = {
    ver: '',
    dob: '',
    nam: EMPTY_PERSON,
    r: null,
    t: null,
    v: null,
}

export const ERROR_CERTIFICATE: CertificateModel = {
    ver: '-1',
    dob: '',
    nam: EMPTY_PERSON,
    r: null,
    t: null,
    v: null,
}

/**
 * @property ver: SchemaVersion
 * @property nam: Person
 * @property dob: Date of birth
 * @property r: CertificateRecovery
 * @property t: CertificateTest
 * @property v: CertificateVaccination
 */
export interface CertificateModel {
    ver: string,
    nam: CertificateModelPerson,
    dob: string,
    r: CertificateRecovery[] | null,
    t: CertificateTest[] | null,
    v: CertificateVaccination[] | null
}


/**
 * @property fn: Family Name
 * @property fnt: Standardised Family Name
 * @property gn: Given Name
 * @property gnt: Standardised Given Name
 */
export interface CertificateModelPerson {
    fn: string,
    gn: string,
    fnt: string,
    gnt: string
}

/**
 * @property tg: Disease
 * @property vp: Vaccine
 * @property mp: MedicalProduct
 * @property ma: AuthHolder
 * @property dn: Dose number
 * @property sd: Total Series of Doses
 * @property dt: Date of Vaccination
 * @property co: Country
 * @property is: Certificate Issuer
 * @property ci: Unique Certificate Identifier
 */
 export interface CertificateVaccination {
    tg: Disease,
    vp: Vaccine,
    mp: MedicalProduct,
    ma: AuthHolder,
    dn: number,
    sd: number,
    dt: string,
    co: string,
    is: string,
    ci: string
}

/**
 * @property tg: Disease
 * @property tt: Test type
 * @property nm: NAA Test Name
 * @property ma: RAT Test name and manufacturer
 * @property sc: Date/Time of Sample Collection
 * @property tr: Test Result
 * @property tc: Testing Centre
 * @property co: Country
 * @property is: Certificate Issuer
 * @property ci: Unique Certificate Identifier
 */
export interface CertificateTest { 
    tg: Disease,
    tt: TestType,
    nm: string,
    ma: TestManufacturer,
    sc: string,
    tr: TestResult,
    tc: string,
    co: string,
    is: string,
    ci: string
}

/**
 * @property tg: Disease
 * @property fr: ISO 8601 Date of First Positive Test Result
 * @property co: Country
 * @property df: ISO 8601 Date: Certificate Valid From
 * @property du: Certificate Valid Until
 * @property is: Certificate Issuer
 * @property ci: Unique Certificate Identifier
 */
export interface CertificateRecovery {
    tg: Disease,
    fr: string,
    co: string,
    is: string,
    df: string,
    du: string,
    ci: string
 };

export enum Disease {
    "COVID-19" = "840539006"
}

export enum Vaccine {
    "SARS-CoV-2 mRNA vaccine" = "1119349007",
    "SARS-CoV-2 antigen vaccine" = "1119305005",
    "covid-19 vaccines" = "J07BX03"
}

export enum MedicalProduct {
    "Comirnaty" = "EU/1/20/1528",
    "COVID-19 Vaccine Moderna" = "EU/1/20/1507",
    "Vaxzevria" = "EU/1/21/1529",
    "COVID-19 Vaccine Janssen" = "EU/1/20/1525",
    "CVnCoV" = "CVnCoV",
    "Convidecia" = "Convidecia",
    "EpiVacCorona" = "EpiVacCorona",
    "CoronaVac" = "CoronaVac",
    "Covaxin (also known as BBV152 A, B, C)" = "Covaxin",
    "Sputnik-V" = "Sputnik-V",
    "BBIBP-CorV" = "BBIBP-CorV",
    "Inactivated SARS-CoV-2 (Vero Cell)" = "Inactivated-SARS-CoV-2-Vero-Cell"
}

export enum AuthHolder {
    "AstraZeneca AB" = "ORG-100001699",
    "Biontech Manufacturing GmbH" = "ORG-100030215",
    "Janssen-Cilag International" = "ORG-100001417",
    "Moderna Biotech Spain S.L." = "ORG-100031184",
    "Curevac AG" = "ORG-100006270",
    "CanSino Biologics" = "ORG-100013793",
    "China Sinopharm International Corp. - Beijing location" = "ORG-100020693",
    "Sinopharm Weiqida Europe Pharmaceutical s.r.o. - Prague location" = "ORG-100010771",
    "Sinopharm Zhijun (Shenzhen) Pharmaceutical Co. Ltd. - Shenzhen location" = "ORG-100024420",
    "Novavax CZ AS" = "ORG-100032020",
    "Vector Institute" = "Vector-Institute",
    "Sinovac Biotech" = "Sinovac-Biotech",
    "Bharat Biotech" = "Bharat-Biotech",
    "Gamaleya Research Institute" = "Gamaleya-Research-Institute"
}

export enum TestResult {
    "Not detected" = "260415000",
    "Detected" = "260373001"
}

export enum TestType {
    "Nucleic acid amplification with probe detection" = "LP6464-4",
    "Rapid immunoassay" = "LP217198-3"
}

export enum TestManufacturer {
    "AAZ-LMB, COVID-VIRO" = "1833",
    "Abbott Rapid Diagnostics, Panbio COVID-19 Ag Rapid Test" = "1232",
    "ACON Laboratories, Inc, Flowflex SARS-CoV-2 Antigen rapid test" = "1468",
    "AMEDA Labordiagnostik GmbH, AMP Rapid Test SARS-CoV-2 Ag" = "1304",
    "Anbio (Xiamen) Biotechnology Co., Ltd, Rapid COVID-19 Antigen Test(Colloidal Gold)" = "1822",
    "Anhui Deep Blue Medical Technology Co., Ltd, COVID-19 (SARS-CoV-2) Antigen Test Kit (Colloidal Gold) - Nasal Swab" = "1815",
    "Anhui Deep Blue Medical Technology Co., Ltd, COVID-19 (SARS-CoV-2) Antigen Test Kit(Colloidal Gold)" = "1736",
    "ArcDia International Ltd, mariPOC SARS-CoV-2" = "768",
    "Asan Pharmaceutical CO., LTD, Asan Easy Test COVID-19 Ag" = "1654",
    "Atlas Link Technology Co., Ltd., NOVA Test® SARS-CoV-2 Antigen Rapid Test Kit (Colloidal Gold Immunochromatography)" = "2010",
    "Azure Biotech Inc, COVID-19 Antigen Rapid Test Device" = "1906",
    "Beijing Hotgen Biotech Co., Ltd, Novel Coronavirus 2019-nCoV Antigen Test (Colloidal Gold)" = "1870",
    "Beijing Lepu Medical Technology Co., Ltd, SARS-CoV-2 Antigen Rapid Test Kit" = "1331",
    "Beijing Wantai Biological Pharmacy Enterprise Co., Ltd, Wantai SARS-CoV-2 Ag Rapid Test (FIA)" = "1484",
    "BIOSYNEX S.A., BIOSYNEX COVID-19 Ag BSS" = "1223",
    "PCL Inc, PCL COVID19 Ag Rapid FIA" = "308",
    "SD BIOSENSOR Inc, STANDARD F COVID-19 Ag FIA" = "344",
    "SD BIOSENSOR Inc, STANDARD Q COVID-19 Ag Test" = "345",
    "Quidel Corporation, Sofia SARS Antigen FIA" = "1097",
    "Sugentech, Inc, SGTi-flex COVID-19 Ag" = "1114",
    "Green Cross Medical Science Corp., GENEDIA W COVID-19 Ag" = "1144",
    "Nal von minden GmbH, NADAL COVID-19 Ag Test" = "1162",
    "CerTest Biotec, CerTest SARS-CoV-2 Card test" = "1173",
    "MEDsan GmbH, MEDsan SARS-CoV-2 Antigen Rapid Test" = "1180",
    "möLab, COVID-19 Rapid Antigen Test" = "1190",
    "Oncosem Onkolojik Sistemler San. ve Tic. A.S., CAT" = "1199",
    "Hangzhou Laihe Biotech Co., Ltd, LYHER Novel Coronavirus (COVID-19) Antigen Test Kit(Colloidal Gold)" = "1215",
    "Siemens Healthineers, CLINITEST Rapid Covid-19 Antigen Test" = "1218",
    "DDS DIAGNOSTIC, Test Rapid Covid-19 Antigen (tampon nazofaringian)" = "1225",
    "BTNX Inc, Rapid Response COVID-19 Antigen Rapid Test" = "1236",
    "GenBody, Inc, Genbody COVID-19 Ag Test" = "1244",
    "VivaChek Biotech (Hangzhou) Co., Ltd, Vivadiag SARS CoV 2 Ag Rapid Test" = "1246",
    "GenSure Biotech Inc, GenSure COVID-19 Antigen Rapid Kit (REF: P2004)" = "1253",
    "Hangzhou AllTest Biotech Co., Ltd, COVID-19 and Influenza A+B Antigen Combo Rapid Test" = "1256",
    "Humasis, Humasis COVID-19 Ag Test" = "1263",
    "Labnovation Technologies Inc, SARS-CoV-2 Antigen Rapid Test Kit" = "1266",
    "LumiQuick Diagnostics Inc, QuickProfile COVID-19 Antigen Test" = "1267",
    "LumiraDX, LumiraDx SARS-CoV-2 Ag Test" = "1268",
    "Precision Biosensor, Inc, Exdia COVID-19 Ag" = "1271",
    "Xiamen Boson Biotech Co. Ltd, Rapid SARS-CoV-2 Antigen Test Card" = "1278",
    "Zhejiang Anji Saianfu Biotech Co., Ltd, reOpenTest COVID-19 Antigen Rapid Test" = "1295",
    "Zhejiang Anji Saianfu Biotech Co., Ltd, AndLucky COVID-19 Antigen Rapid Test" = "1296",
    "SGA Medikal, V-Chek SARS-CoV-2 Ag Rapid Test Kit (Colloidal Gold)" = "1319",
    "Joinstar Biomedical Technology Co., Ltd, COVID-19 Rapid Antigen Test (Colloidal Gold)" = "1333",
    "Qingdao Hightop Biotech Co., Ltd, SARS-CoV-2 Antigen Rapid Test (Immunochromatography)" = "1341",
    "Zhezhiang Orient Gene Biotech Co., Ltd, Coronavirus Ag Rapid Test Cassette (Swab)" = "1343",
    "Guangdong Wesail Biotech Co., Ltd, COVID-19 Ag Test Kit" = "1360",
    "Hangzhou Clongene Biotech Co., Ltd, Covid-19 Antigen Rapid Test Kit" = "1363",
    "Hangzhou Clongene Biotech Co., Ltd, COVID-19/Influenza A+B Antigen Combo Rapid Test" = "1365",
    "DIALAB GmbH, DIAQUICK COVID-19 Ag Cassette" = "1375",
    "Hangzhou Testsea Biotechnology Co., Ltd, COVID-19 Antigen Test Cassette" = "1392",
    "NanoEntek, FREND COVID-19 Ag" = "1420",
    "Guangzhou Wondfo Biotech Co., Ltd, Wondfo 2019-nCoV Antigen Test (Lateral Flow Method)" = "1437",
    "Vitrosens Biotechnology Co., Ltd, RapidFor SARS-CoV-2 Rapid Ag Test" = "1443",
    "Xiamen Wiz Biotech Co., Ltd, SARS-CoV-2 Antigen Rapid Test" = "1456",
    "TODA PHARMA, TODA CORONADIAG Ag" = "1466",
    "MP Biomedicals, Rapid SARS-CoV-2 Antigen Test Card" = "1481",
    "Safecare Biotech (Hangzhou) Co. Ltd, COVID-19 Antigen Rapid Test Kit (Swab)" = "1489",
    "Safecare Biotech (Hangzhou) Co. Ltd, Multi-Respiratory Virus Antigen Test Kit(Swab) (Influenza A+B/ COVID-19)" = "1490",
    "Shenzhen Zhenrui Biotechnology Co., Ltd, Zhenrui ®COVID-19 Antigen Test Cassette" = "1574",
    "Roche (SD BIOSENSOR), SARS-CoV-2 Antigen Rapid Test" = "1604",
    "RapiGEN Inc, BIOCREDIT COVID-19 Ag - SARS-CoV 2 Antigen test" = "1606",
    "Guangdong Hecin Scientific, Inc., 2019-nCoV Antigen Test Kit (colloidal gold method)" = "1747",
    "Xiamen AmonMed Biotechnology Co., Ltd, COVID-19 Antigen Rapid Test Kit (Colloidal Gold)" = "1763",
    "JOYSBIO (Tianjin) Biotechnology Co., Ltd, SARS-CoV-2 Antigen Rapid Test Kit (Colloidal Gold)" = "1764",
    "Healgen Scientific, Coronavirus Ag Rapid Test Cassette" = "1767",
    "Shenzhen Watmind Medical Co., Ltd, SARS-CoV-2 Ag Diagnostic Test Kit (Colloidal Gold)" = "1769",
    "Hangzhou Immuno Biotech Co.,Ltd, Immunobio SARS-CoV-2 Antigen ANTERIOR NASAL Rapid Test Kit (minimal invasive)" = "1844",
    "Xiamen Wiz Biotech Co., Ltd, SARS-CoV-2 Antigen Rapid Test (Colloidal Gold)" = "1884",
    "Core Technology Co., Ltd, Coretests COVID-19 Ag Test" = "1919",
    "Tody Laboratories Int., Coronavirus (SARS-CoV 2) Antigen - Oral Fluid" = "1934",
    "Shenzhen Ultra-Diagnostics Biotec.Co.,Ltd, SARS-CoV-2 Antigen Test Kit" = "2017"
}