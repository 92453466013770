import "./Header.css";
import { Separator } from "./Separator";

interface HeaderProps {
}
export function Header(props: HeaderProps) {
    return (
        <section>
            <h1>SM Digital COVID Certificate</h1>
            <Separator />
            <h1>SM Certificat COVID Numérique</h1>
            <div className="dcc-country-badge">
                <span>SM</span>
            </div>
            <div className="dcc-certificate-header__flag">
                <img src="flag-san-marino.jpg" alt="Flag" />
            </div>
            <div className="dcc-certificate-header__subtitle">Repubblica di San Marino</div>
            <div className="dcc-certificate-header__subtitle">République de Saint-Marin</div>
            <div className="dcc-certificate-header__subtitle">Republic of San Marino</div>
        </section>
    )
}