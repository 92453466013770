import { CertificateModel, Disease } from "./certificate.model";
import "./Recoveries.css";

function EnumToMap(data: any): Record<string, string> {
    return Object.keys(data)
        .reduce((prev, current) => {
            const key = data[current];
            prev[key] = current;

            return prev;
        }, {} as Record<string, string>)
}

const DISEASE_MAP: Record<string, string> = EnumToMap(Disease);

interface RecoveriesProps {
    certificate: CertificateModel
}
export function Recoveries(props: RecoveriesProps) {
    const { certificate } = props;

    return (
        <section>
            <h3>Certificato di guarigione</h3>
            <h3>Certificate of Recovery</h3>
            {
                !certificate.r
                    ? undefined
                    : certificate.r.map((recovery, index) => (
                        <div key={index} >
                            <div className="dcc-data" >
                                <span>Malattia o agente bersaglio:</span>
                                <span>disease or agent targeted:</span>
                                <span>{DISEASE_MAP[recovery.tg]}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Data del primo test positivo:</span>
                                <span>date of the first positive test result:</span>
                                <span>{recovery.fr}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Stato di vaccinazione:</span>
                                <span>Country of vaccination:</span>
                                <span>{recovery.co}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Struttura che detiene il certificato:</span>
                                <span>Certificate issuer:</span>
                                <span>{recovery.is}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Validita' del certificato dal {recovery.df} al {recovery.du}</span>
                                <span>Certificate valid from {recovery.df} until {recovery.du}</span>
                            </div>
                        </div>
                    ))
            }
        </section>
    )
}