import Moment from 'moment';

import { CertificateModel, Disease, TestType, TestManufacturer, TestResult } from "./certificate.model";
import "./Tests.css";

function EnumToMap(data: any): Record<string, string> {
    return Object.keys(data)
        .reduce((prev, current) => {
            const key = data[current];
            prev[key] = current;

            return prev;
        }, {} as Record<string, string>)
}

const DISEASE_MAP: Record<string, string> = EnumToMap(Disease);
const TEST_TYPE_MAP: Record<string, string> = EnumToMap(TestType);
const TEST_MANUFACTURER_MAP: Record<string, string> = EnumToMap(TestManufacturer);
const TEST_RESULT_MAP: Record<string, string> = EnumToMap(TestResult);

interface TestsProps {
    certificate: CertificateModel
}
export function Tests(props: TestsProps) {
    const { certificate } = props;

    return (
        <section>
            <h3>Certificato di Test Tampone</h3>
            <h3>Swab Test Certificate</h3>
            {
                !certificate.t
                    ? undefined
                    : certificate.t.map((test, index) => (
                        <div key={index} >
                            <div className="dcc-data" >
                                <span>Malattia o agente bersaglio:</span>
                                <span>disease or agent targeted:</span>
                                <span>{DISEASE_MAP[test.tg]}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Tipologia di test effettuato:</span>
                                <span>The type of test:</span>
                                <span>{TEST_TYPE_MAP[test.tt]}</span>
                            </div>
                            {test.nm && (
                                <div className="dcc-data" >
                                    <span>Nome del test:</span>
                                    <span>Test name:</span>
                                    <span>{test.nm}</span>
                                </div>
                            )}
                            {test.ma && (
                                <div className="dcc-data" >
                                    <span>Produttore del test</span>
                                    <span>Test manufacturer:</span>
                                    <span>{TEST_MANUFACTURER_MAP[test.ma]}</span>
                                </div>
                            )}
                            <div className="dcc-data" >
                                <span>Data e orario della raccolta del campione del test:</span>
                                <span>Date and time of the test sample collection:</span>
                                <span>{ Moment(test.sc).format('DD/MM/YYYY HH:mm') }</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Risultato del test:</span>
                                <span>Result of the test:</span>
                                <span>{TEST_RESULT_MAP[test.tr]}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Centro o struttura in cui è stato effettuato il test:</span>
                                <span>Testing center or facility:</span>
                                <span>{test.tc}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Stato in cui è stato effettuato il test:</span>
                                <span>Member State of test:</span>
                                <span>{test.co}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Struttura che detiene il certificato:</span>
                                <span>Certificate issuer:</span>
                                <span>{test.is}</span>
                            </div>
                        </div>
                    ))
            }
        </section>
    )
}