import { ConfigBase } from './config';
import { ConfigDev } from './config.dev';
import { ConfigProd } from './config.prod';

export const BUILD_DATE = document.querySelector('meta[name="build"]')?.getAttribute('content') || '';

export const config = {
    ...ConfigBase,
    ...process.env.NODE_ENV === 'development'
        ? ConfigDev
        : ConfigProd
};
