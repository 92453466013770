import "./Person.css";
import { CertificateModel } from "./certificate.model";
import { Separator } from "./Separator";

interface PersonProps {
    certificate: CertificateModel
}
export function Person(props: PersonProps) {
    const { certificate } = props;
    const ci = certificate.v && certificate.v.length > 0
        ? certificate.v[0].ci
        : certificate.t && certificate.t.length > 0
            ? certificate.t[0].ci
            : certificate.r && certificate.r.length > 0
                ? certificate.r[0].ci
                : "";
    return (
        <section>
            <Separator image="certificate" />
            <div className="dcc-data dcc-data--large">
                <span>Surname(s) and Forename(s)</span>
                <span>Cognome e Nome</span>
                <span>{certificate.nam.fn} {certificate.nam.gn}</span>
            </div>
            <div className="dcc-data dcc-data--large">
                <span>Date of Birth (YYYY-DD-MM)</span>
                <span>Data di nascita (AAAA-GG-MM)</span>
                <span>{certificate.dob}</span>
            </div>
            <div className="dcc-data dcc-data--large">
                <span>Unique Certificate Identifier</span>
                <span>Unique Certificate Identifier</span>
                <span>{ci ? ci.split("01:")[1].split("#")[0] : ""}</span>
            </div>
        </section>
    )
}