import "./Footer.css";
import { Separator } from "./Separator";
import { config } from '../environment';

interface FooterProps {
    transactionId: string | null;
}
export function Footer(props: FooterProps) {
    return (
        <section>
            <Separator image="flag" />
            <img className="dcc-certificate-footer__logo" alt="Logo San Marino" src="logo-san-marino.png" />
            <h2>San Marino</h2>
            <p>Issued by San Marino Social Security Institute. The universal QRCode has been issued in compliance with the Decreto Legge 16 June 2021 n.109. It can be used as alternative to the EU QRCode in countries that don't adhere to the European Regulation of the Digital Covid19 certificate</p>
            <p>This certificate is not a travel document. The scientific evidence on COVID-19 vaccination, testing and recovery continues to evolve, also in view of new variant of concerne of the virus. Before travelling, please the applicable public health measures and related restrictions applied at the point of destination. Relevant information can be found here:</p>
            <a href="https://www.smdcc.sm" target="_blank" rel="noreferrer">https://www.smdcc.sm</a>
            <p>This certificate is trusted and has been uploaded into the VeChain Thor Blockchain.
                Follow <a href={`${config.vechainBaseUrl}${props.transactionId}`} target="_blank" rel="noreferrer">this link</a> to view the transaction
            </p>
            <div className="dcc-certificate-footer__partners">
                <img src="logo-trusted.png" alt="Trusted" />
            </div>
            <div className="dcc-certificate-footer__partners">
                <img src="logo-vechain.png" alt="VeChain" />
                <img src="logo-dnv.png" alt="DNV" />
                <img src="logo-edg.png" alt="EDG" />
            </div>
        </section>
    )
}