import { useEffect } from "react";
import "./Home.css";

export function Home() {

    useEffect(() => {
        document.body.classList.add("landing")
        return () => {
            document.body.classList.remove("landing")
        }
    }, [])

    const myHtml = 
    `      
   <!--Start Navigation-->
		<header id="header">
				<div class="container">
					<div class="row">
						<div class="col-sm-12">
							<button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-menu">
								<span class="sr-only">Toggle navigation</span>
								<span class="fa fa-bars"></span>
								<span class="icon-bar"></span>
								<span class="icon-bar"></span>
							</button>
                            <!--Start Logo -->
							<div class="logo-nav">
								<a href="index.html">
									<img src="images/logo.png" alt="Company logo" style="max-width: 10%" />
								</a>
							</div>
                            <!--End Logo -->
							<div class="clear-toggle"></div>
							<div id="main-menu" class="collapse scroll navbar-right">
								<ul class="nav">
                                
									<li class="active"> <a href="#home">Home</a> </li>
									
									<li> <a href="#about">Cos'è</a> </li>
                                    
                                     <li> <a href="#team">Funzionamento</a> </li>
                                   
								    <li> <a href="#services">Durata</a> </li>
																		
									<li> <a href="#contact">Contatti</a></li>
                	
								</ul>
							</div><!-- end main-menu -->
						</div>
					</div>
				</div>
			</header>
    <!--End Navigation-->

      
        <!-- Start Slider  -->
        <section id="home" class="home">
             <div class="slider-overlay"></div>
            <div class="flexslider">
                <ul class="slides scroll">
                    <li class="first">
                        <div class="slider-text-wrapper">  
                            <div class="container">
                                <div class="big">Ripartiamo in sicurezza</div>          
                                <div class="small">San Marino Digital Covid Certificate</div>
                            </div>       
                        </div>
                        <img src="images/slider/1.jpg" alt="">
                    </li>
                </ul>
            </div>
        </section>
          <!-- End Slider  -->


  <!--Start Features-->
  <section  id="about" class="section">
       <div class="container">
           <div class="row">

            <div class="title-box text-center">
                <h2 class="title">Cos'è</h2>
             </div>
              
              <!-- Features Icon-->
              <div class="col-md-12">
                <div class="post-description">							
                    <p>
                        La <b>Certificazione verde COVID-19</b> - EU digital COVID certificate nasce su proposta della Commissione europea (https://ec.europa.eu/health/ehealth/covid-19_en) per agevolare la libera circolazione in sicurezza dei cittadini nell'Unione europea durante la pandemia di COVID-19.​ Il Digital Covid Certificate <b>è una certificazione digitale e stampabile (cartacea)</b>, che prova lo stato di immunità del cittadino rispetto al virus del COVID-19.
                    </p>
                    <p>
                        A <b>San Marino</b>, il Digital Covid Certificate è caratterizzato da <b> due codici</b> a barre bidimensionali, il <b>QRCode Europeo e il QRCode Universale</b>, firmati attraverso un sigillo elettronico qualificato al fine da renderne impossibile la contraffazione. Viene emessa soltanto attraverso la piattaforma nazionale dell'<b>stituto di Sicurezza Sociale</b>. 
                    </p>
                    <p>
                        Inoltre, per ottemperare alle esigenze di interoperabilità fra gli stati membri dell'Unione Europea, lo Stato di San Marino ha avviato le procedure tecniche e burocratiche per connetterrsi al gateway Europeo  (https://github.com/eu-digital-green-certificates/) e rendere possibile il riconoscimento e la validità dei Certificati emessi.
                    </p>
                </div>
              </div>              
           </div> <!-- /.row-->
       </div> <!-- /.container-->
  </section> 
  <!--End Features-->

  <!--Start Team-->
  <section id="team" class="section">
		<div class="container">
            <div class="row">
                    
             <div class="title-box text-center">
                <h2 class="title">Funzionamento</h2>
             </div>
              
             </div>

             <div class="post-description">							
                <p>
                    Dopo la vaccinazione, un test negativo oppure dopo una guarigione da COVID-19, la Certificazione viene emessa automaticamente in formato digitale e stampabile.
                </p>
                <p>
                    La certificazione contiene due QRCode, quello Europeo e quello Universale. Entrambi possono essere presentati agli operatori che richiedono la verifica del Certificato.
                </p>
            </div>
			
                <!-- Team -->
				<div class="team-items team-carousel">
                      <!-- Team Member #1 -->
                      <div class="item">
                          <h4>QRCode Europeo</h4>
                          <p>può essere usato per circolare all'interno dei Paesi Ue/area Schengen, e può essere verificato solo mediante una App di Verifica valida all'interno del territorio della Comunità Europea. </p>
                      </div>
                      <!-- End Member -->
                      
                      <!-- Team Member #2 -->
                      <div class="item">
                          <h4>QRCode Universale</h4>
                          <p>non richiede l'utilizzo di una App di Verifica ed è sufficiente  un dispositivo (smartphone o tablet) connesso ad internet che rimanderà automaticamente alla pagina del sito ufficiale (www.smdcc.sm) con le informazioni che certificano la validità e genuinità del Certificato presentato.</p>
                      </div>
                      <!-- End Member -->
				</div>
                <!-- End Team -->
			</div>
            <!-- End Content -->
	</section>
  <!--End Team-->
 
  <!--Start Services-->
   <section id="services" class="section">
        <div class="container">
            <div class="row">
            
                 <div class="title-box text-center">
                    <h2 class="title">Durata del Certificato</h2>
                 </div>
                 <div class="post-description">							
                     <p>
                        La durata della Certificazione varia a seconda della prestazione sanitaria a cui è collegata
                     </p>
                </div>
               <!--Services Item-->
               <div class="col-md-4">
                   <div class="services-box">
                       <div class="services-icon"> <i class="icon-basket"></i> </div> 
                       <div class="services-desc">
                          <h4>In caso di vaccinazione</h4>
                          <p>
                             Per la prima dose dei vaccini che ne richiedono due, la Certificazione sarà sarà valida a partire dal 15° giorno dopo la somministrazione. Per i vaccini di tipo mRNA (Pfizer BioNTech, Moderna) la validità è di 42 giorni, mentre per i vaccini a vettore virale (Sputnik V, Astrazeneca) la coperturà è valida fino a 84 giorni dalla somministrazione.
                          </p>
                          <p>
                             Nei casi di seconda dose o dose unica per pregressa infezione: la Certificazione sarà generata entro un paio di giorni e avrà validità per 270 giorni (circa nove mesi) dalla data di somministrazione.
                          </p>
                          <p>                                                           
                             Nei casi di vaccino monodose: la Certificazione sarà generata dal 15° giorno dopo la somministrazione e avrà validità per 270 giorni (circa nove mesi).
                          </p>
                       </div>
                   </div>
               </div>
                <!--End services Item-->
                
                <!--Services Item-->
               <div class="col-md-4">
                   <div class="services-box">
                       <div class="services-icon"> <i class="icon-shield"></i> </div> 
                       <div class="services-desc">
                          <h4>Nei casi di tampone negativo</h4>
                          <p>la Certificazione sarà generata in poche ore e avrà validità per 48 ore dall’ora del prelievo. </p>
                       </div>
                   </div>
               </div>
               <!--End services Item-->
               
               <!--Services Item-->
               <div class="col-md-4">
                   <div class="services-box">
                       <div class="services-icon"> <i class="icon-hotairballoon"></i> </div> 
                       <div class="services-desc">
                          <h4>Nei casi di guarigione da COVID-19</h4>
                          <p>la Certificazione sarà generata entro il giorno seguente e avrà validità per 180 giorni (6 mesi). </p>
                       </div>
                   </div>
               </div>
                <!--End services Item-->
            </div> <!--/.row-->
        </div> <!--/.container-->
   </section> 
  <!--End Services-->
 
   <!--Start Contact-->
   <section id="contact" class="section parallax">
      <div class="overlay"></div>
       <div class="container">
           <div class="row">
           
                 <div class="title-box text-center white">
                    <h2 class="title">Contact</h2>
                 </div>
             </div>

               <!--Start contact form-->
               <div class="col-md-8 col-md-offset-2 contact-form">
               
                    <div class="contact-info text-center">
                       <p>Istituto per la Sicurezza Sociale</p>
                       <p>Via Scialoja, 20 Cailungo </p>
                       <p>47893 Repubblica di San Marino</p>
                       <p>Telefono 0549 994111 Fax 0549 906240</p>
                       <p>E-mail: urp@iss.sm</p>
                       <p>Codice Operatore Economico SM02298</p>
                    </div>
               </div>
              <!--End contact form-->
              
       </div> <!-- /.container-->
   </section>
   <!--End Contact-->

   <a href="#" class="scrollup"> <i class="fa fa-chevron-up"> </i> </a>
    `

    return (
        <div dangerouslySetInnerHTML={{ __html: myHtml }} />
    )
}
