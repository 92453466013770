import "./Separator.css"

interface SeparatorProps {
    image?: string;
}
export function Separator(props: SeparatorProps) {
    return (
        <div className={`dcc-separator${!props.image ? '' : ' dcc-separator--image'}`}>
            {
                !props.image
                    ? null
                    : (
                        <div>
                            <img src={`separator-${props.image}.png`} alt="separator" />
                        </div>
                    )
            }
        </div>
    )
}