import { CertificateModel, Disease, Vaccine, MedicalProduct, AuthHolder } from "./certificate.model";
import "./Vaccinations.css";

function EnumToMap(data: any): Record<string, string> {
    return Object.keys(data)
        .reduce((prev, current) => {
            const key = data[current];
            prev[key] = current;

            return prev;
        }, {} as Record<string, string>)
}

const DISEASE_MAP: Record<string, string> = EnumToMap(Disease);
const VACCINE_MAP: Record<string, string> = EnumToMap(Vaccine);
const MEDICAL_PRODUCT_MAP: Record<string, string> = EnumToMap(MedicalProduct);
const AUTH_HOLDER_MAP: Record<string, string> = EnumToMap(AuthHolder);

interface VaccinationsProps {
    certificate: CertificateModel
}
export function Vaccinations(props: VaccinationsProps) {
    const { certificate } = props;

    return (
        <section>
            <h3>Certificato di vaccinazione</h3>
            <h3>Vaccination certificate</h3>
            {
                !certificate.v
                    ? undefined
                    : certificate.v.map((vaccination, index) => (
                        <div key={index} >
                            <div className="dcc-data" >
                                <span>Malattia o agente bersaglio:</span>
                                <span>disease or agent targeted:</span>
                                <span>{DISEASE_MAP[vaccination.tg]}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Tipo di vaccino:</span>
                                <span>Vaccine/prophylaxis:</span>
                                <span>{VACCINE_MAP[vaccination.vp]}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Prodotto medico vaccinale (denominazione del vaccino):</span>
                                <span>Vaccine medical product:</span>
                                <span>{MEDICAL_PRODUCT_MAP[vaccination.mp]}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Produttore o titolare dell'autorizzazione all'immissione in commercio del vaccino:</span>
                                <span>Vaccine marketing authorization holder or manufacturer:</span>
                                <span>{AUTH_HOLDER_MAP[vaccination.ma]}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Numero delle dosi effettuate e numero totale di dosi previste per l'intestatario del certificato:</span>
                                <span>Number in a series of vaccinations/doses and the overall number of doses in the series:</span>
                                <span>{vaccination.dn}/{vaccination.sd}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Data dell'ultima somministrazione effettuata:</span>
                                <span>Date of vaccination, indicating the date of the latest dose received:</span>
                                <span>{vaccination.dt}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Stato di vaccinazione:</span>
                                <span>Country of vaccination:</span>
                                <span>{vaccination.co}</span>
                            </div>
                            <div className="dcc-data" >
                                <span>Struttura che detiene il certificato:</span>
                                <span>Certificate issuer:</span>
                                <span>{vaccination.is}</span>
                            </div>
                        </div>
                    ))
            }
        </section>
    )
}