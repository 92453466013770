import "./Certificate.css";
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CertificateModel, EMPTY_CERTIFICATE, ERROR_CERTIFICATE } from './certificate.model';
import { http } from '../http-client';
import { Header } from './Header';
import { Person } from './Person';
import { Vaccinations } from './Vaccinations';
import { Tests } from './Tests';
import { Recoveries } from './Recoveries';
import { Footer } from './Footer';

const log = Log.getLogger('Certificate');
log.debug('import');

function useQuery(param: string) {
    const location = useLocation();
    const search = new URLSearchParams(location.search);

    return search.get(param);
}

export function Certificate() {
    const { guid } = useParams<{ guid: string }>();
    const code = useQuery('code');
    const transactionId = useQuery('t');
    const history = useHistory();
    const [cert, setCert] = useState(EMPTY_CERTIFICATE);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        log.info('Download data');

        if (!guid || !code) {
            log.info('Invalid params, redirect to 404');

            history.push('/');

            return;
        }

        const ac = new AbortController();
        setIsLoading(true);
        http.post<any, CertificateModel>('/api/ValidateCertificateUNI', {
            "Code": code
        })
            .then(res => {
                log.info(res)
                setCert(res);
            })
            .catch(() => {
                log.error('Invalid certificate code');
                setCert(ERROR_CERTIFICATE);
            })
            .finally(() => {
                setIsLoading(false);
            })

        return () => ac.abort();
    }, [guid, code, history])

    let SectionComponent = cert.v != null ? Vaccinations : cert.t != null ? Tests : Recoveries;

    return (
        <>
            {
                isLoading
                    ? <div className="dcc-certificate__loading-badge">Loading</div>
                    : undefined
            }
            {
                cert === ERROR_CERTIFICATE
                    ? (
                        <div>
                            <Header />
                            <h1 className="color--error">Invalid or unknown certificate</h1>
                        </div>
                    )
                    : (
                        <div className="dcc-certificate">
                            <Header />
                            <Person certificate={cert} />
                            <SectionComponent certificate={cert} />
                            <Footer transactionId={transactionId} />
                        </div>
                    )
            }
        </>
    )
}
